import { render, staticRenderFns } from "./artwork.vue?vue&type=template&id=0ba984d3&scoped=true"
import script from "./artwork.vue?vue&type=script&lang=js"
export * from "./artwork.vue?vue&type=script&lang=js"
import style0 from "./artwork.vue?vue&type=style&index=0&id=0ba984d3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba984d3",
  null
  
)

export default component.exports