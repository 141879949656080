<template>
  <div class="tabs">
    工艺图
  </div>
</template>

<script>
export default {
    name: 'artwork',
    components: {},
    data() {
        return {}
    },
    methods:{}
}
</script>

<style scoped lang="scss">
.tabs {
  margin: 10px;
  background-color: #fff;
  height: calc(100vh - 173px);
  .topSearch{
        color: #0F0F0F;
        border-bottom: 1px solid #E3E3E3;
        padding: 16px 10px;
        .selectBox{
            width: 200px;
            margin: 0 30px 0 0;
        }
        .searchBtn{
            display: inline-block;
        }
    }
}
</style>